import React, { Component } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import Pagination from "react-js-pagination";
import $ from 'jquery';
import Header from '../Layouts/Header';
import MenuSidebar from '../Layouts/MenuSidebar';
import { scrollToTopValidate,PageTitle } from "../Helpers/SettingHelper";
/* import { GET_VIEWUSERLIST } from '../../actions'; */
import { apiUrl} from'../Config/Config';
import axios from 'axios';
import {
  Row,
  Col,
  Modal,
  Table,
  OverlayTrigger,
  ButtonToolbar,
  Tooltip,
  Button
} from "react-bootstrap";
const withRouter = WrappedComponent => props => {
	const params = useParams();
	const  navigate = useNavigate();
	// etc... other react-router-dom v6 hooks
  
	return (
	  <WrappedComponent
		{...props}
		params={params}
		navigate ={navigate}
		// etc...
	  />
	);
  };
class View extends Component {
  
  constructor(props)
  {
    super(props); 
    const userid = (typeof props.params.ID === 'undefined') ? '' : props.params.ID;

    this.state = {      
      userid: userid,
      userlistview:'',
      familtlistview:'',
    };

    axios.get(apiUrl+"user/listdetail?id="+userid).then(res => {
			if(res.data.status == 'success'){
				const formdata = res.data.userlist;
			if(formdata.status === 'A'){
				this.setState({selectedOption:{value: 'active', label: 'Active'}});
			}else{
				this.setState({selectedOption:{value: 'inactive', label: 'Inactive'}});

			}
			this.setState({userlistview:res.data.userlist});
						
			}
			
		}); 
  }

   
   componentDidMount() {
     document.title = PageTitle('User View');
   }

   componentDidUpdate(prevProps,prevState){

   }
   componentWillReceiveProps(Props){
   
    
   }
   
  render() {
    

    var mobileno = ((this.state.userlistview.mobile === null)||((this.state.userlistview.mobile === ''))) ?  this.state.userlistview.mobile = 'N/A' : this.state.userlistview.mobile;

    var lastname = ((this.state.userlistview.lastname === null)||(this.state.userlistview.lastname === "")) ?  this.state.userlistview.lastname = 'N/A' : this.state.userlistview.lastname;

    if(this.state.userlistview.status == 'A'){
      var status = 'Active';
    }else{
        var status = 'Inactive';
    }
    return (
      <div className="wrapper"> 
 
    <Header />
    <MenuSidebar currentpage="user" />  
      
      <div className="content"> 
        <div className="content-wrapper">
         <div class="content-wrapper-before"></div>
            <div className="content-body">
               <div className="form-wrapper leaa-admin-head card">
                <div className="title">
                  <h3>User Details</h3>
                </div>
                <div className="form-row">
                    <div className="details_left">
                        <div className="view-details">
                          <div className="view-details-one">
                              <p><b>Name<span>:</span> </b></p>
                          </div>
                          <div className="view-details-two">
                              <p>{this.state.userlistview.firstname} {this.state.userlistview.lastname}</p>
                          </div>
                        </div>
                        {/* <div className="view-details">
                          <div className="view-details-one">
                              <p><b>First Name<span>:</span> </b></p>
                          </div>
                          <div className="view-details-two">
                              <p>{this.state.userlistview.firstname}</p>
                          </div>
                        </div>
                        <div className="view-details">
                          <div className="view-details-one">
                              <p><b>Last Name<span>:</span> </b></p>
                          </div>
                          <div className="view-details-two">
                              <p>{lastname}</p>
                          </div>
                        </div> */}
                        <div className="view-details">
                          <div className="view-details-one">
                              <p><b>User Name<span>:</span> </b></p>
                          </div>
                          <div className="view-details-two">
                              <p>{this.state.userlistview.username}</p>
                          </div>
                        </div>
                        <div className="view-details">
                          <div className="view-details-one">
                              <p><b>Password<span>:</span> </b></p>
                          </div>
                          <div className="view-details-two">
                              <p>{this.state.userlistview.orgpwd}</p>
                          </div>
                        </div>
                       
                        <div className="view-details">
                          <div className="view-details-one">
                              <p><b>Email<span>:</span> </b></p>
                          </div>
                          <div className="view-details-two">
                              <p>{this.state.userlistview.email}</p>
                          </div>
                        </div>
                        <div className="view-details">
                          <div className="view-details-one">
                              <p><b>Contact<span>:</span> </b></p>
                          </div>
                          <div className="view-details-two">
                              <p>{mobileno}</p>
                          </div>
                          
                        </div>
                        <div className="view-details">
                          <div className="view-details-one">
                              <p><b>Domain<span>:</span> </b></p>
                          </div>
                          <div className="view-details-two">
                          <p>{this.state.userlistview.domain_name}</p>
                          </div>
                          
                        </div>
                        <div className="view-details">
                          <div className="view-details-one">
                              <p><b>Status<span>:</span> </b></p>
                          </div>
                          <div className="view-details-two">
                              <p>{status}</p>
                          </div>
                          
                        </div>

                      
                        



                    </div>
                </div>
                 
                  
               
              </div>            
            
          </div>
         </div> 
        </div>        
    </div>
    );
  }
}
export default withRouter(View);
