import React, { Component } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import Header from '../Layouts/Header';
import MenuSidebar from '../Layouts/MenuSidebar';
import $ from 'jquery';
import Select from 'react-select';
import { lang } from '../Helpers/lang';
import axios from 'axios';
import { apiUrl} from'../Config/Config';
import "react-datepicker/dist/react-datepicker.css";


const withRouter = WrappedComponent => props => {
	const params = useParams();
	const  navigate = useNavigate();
	// etc... other react-router-dom v6 hooks
  
	return (
	  <WrappedComponent
		{...props}
		params={params}
		navigate ={navigate}
		// etc...
	  />
	);
  };
class Add extends Component {

	constructor(props)
	{
		// CheckAuth();
		super();	
		this.state = {
			tab_name:'',
			title:'',
			description:'',
			content:'',
			selectedOption:{ value: 'active', label: 'Active'},
			selectedvalue:'active',
			selectedtype : '',
			selectedtypeValue : '',
			image_preview:'',
			image_name:'',
			image:'',
			fileExtension: '',
		};

		this.handleInputChange = this.handleInputChange.bind(this);
		this.handleChange = this.handleChange.bind(this);

    }
	handleTypeChange = selectedOption => {
        this.setState({selectedtype:selectedOption})
        this.setState({  selectedtypeValue : selectedOption.value});
    };  
	handleChange = selectedOption => {
        this.setState({selectedOption})
        this.setState({  selectedvalue : selectedOption.value});
   };

   handleInputChange(event) {
      const {name, value} = event.target;      
      this.setState({
          [name]: value
        });

  }

  onChangeHandler=event=>{
	let reader = new FileReader();
	const file = event.target.files[0];
	const extension = file.name.split('.').pop();
	reader.onloadend = () => {
	  this.setState({
		image_name: file.name,
		image_preview: reader.result,
		image: file,
		fileExtension:extension,
		
	  });
	};
	reader.readAsDataURL(file);

	
  }

	handleFormSubmit = () => {
		if(this.validateForm()){ 
			this.setState({Loading:true});
			
			const formPayload = this.state;
			var qs = require('qs');
			var status = '';
			if(formPayload.selectedvalue === '' && formPayload.selectedvalue!== 0){
			status =formPayload.selectedOption.value;
			}else{
			status = formPayload.selectedvalue;
			}

			var postObject = {
				admin_id : localStorage.getItem("admin_id"),
				tab_name: formPayload.tab_name,
				title : formPayload.title,
				description: formPayload.description,
				content_type: formPayload.selectedtypeValue,
				image : formPayload.image,
				// content: formPayload.content,
				status:  status,
			
			};
			let formData = new FormData();
			for(let k in postObject) {
			formData.append(k, postObject[k]);
			}		       
			axios.post(apiUrl+"tabmanagement/add",formData ).then(res => {
				if(res.data.status === "success"){	
					$('.success_message').html('<div class="status_sucess"><h3>'+res.data.message+'</h3></div>');
							setTimeout(
							function() {
								
								$('.success_message').html('');
								this.props.navigate('/tabmanagement');
							}
							.bind(this),
							3000
							);
				}else{
					$('.success_message').html('<div class="status_sucess"><h3>'+res.data.message+'</h3></div>');
					
					setTimeout(
					function() {
						$('.success_message').html('');
					}
					.bind(this),
					3000
					);
				}
			});
		}
}

validateForm() {
	//const {admin_company_name,admin_fname} = this.state;
	const {tab_name,title,description,selectedtypeValue,image} = this.state;
	let errors = {};
	  let formIsValid = true;
	  
	if (!tab_name) {
		formIsValid = false;
		$('.errortab_name').html('<span class="errorspan">Please fill the field</span>');
	}else if(tab_name){
		if (/^[a-zA-Z\s]*$/.test(tab_name)) {
		$('.errortab_name').html('');
		}else{
			formIsValid = false;
			$('.errortab_name').html('Please enter valid tab name');
		}
	}
	
	if (!title) {
		formIsValid = false;
		$('.errortab_title').html('<span class="errorspan">Please fill the field</span>');
	}else if(tab_name){
		$('.errortab_title').html('');
	}

	if (!description) {
		formIsValid = false;
		$('.errordescription').html('<span class="errorspan">Please fill the field</span>');
	}else if(description){
		$('.errordescription').html('');
	}
	if (!image) {
		formIsValid = false;
		$('.errorfile').html('<span class="errorspan">Please fill the feild</span>');
			}else{
				$('.errorfile').html('');
			}
			if (image) {		
	if (!image.name.match(/\.(jpg|jpeg|png|svg)$/)) {
		formIsValid = false;
			 $('.errorfile').html('<span class="errorspan">Please select valid type</span>');
		   
	  }else{
		$('.errorfile').html('');
	  }
	}
	if (!selectedtypeValue) {
		formIsValid = false;
		$('.errorcontenttype').html('<span class="errorspan">Please fill the field</span>');
	}else if(selectedtypeValue){
		$('.errorcontenttype').html('');
	}
	

	this.setState({
		errors: errors
	});
	return formIsValid;
}


  render() {

	const {selectedOption,selectedtype} = this.state;
	var	preview = <img className="img_class" src={this.state.image_preview} alt="" />;

    return (
      <div className="wrapper"> 
 
	  <Header />
      <MenuSidebar currentpage="tabmanagement" />  

	<div id="location-error"></div>
	<div className="content">	
		<div className="content-wrapper">
		<div class="content-wrapper-before"></div>
			<div className="form-wrapper leaa-admin-head card">
			<div className="success_message"></div>
			<div className="title">
				<h4>Add Tab</h4>				
			</div>
			 <form className="edit"  onSubmit={ e => { this.handleFormSubmit(this); e.preventDefault(); }}>
			<div className="form-row">
			<div className="form-left">
				<div className="form-group">
					<label>Tab Name<span class="required" style={{ color: "red" }} >* </span> : </label>
				    <input type="text" name="tab_name" onChange={this.handleInputChange} className="form-control" value={this.state.tab_name}/>
					<div className="errortab_name"></div>
				</div>
				<div className="default-font">
				<div className="form-group">					
					<label>Content Type<span class="required" style={{ color: "red" }} >*</span></label>
						   <Select 
						   value={selectedtype}
                            options={lang.common.contentType_option} 
                            onChange={this.handleTypeChange}
                            />
							<div className="errorcontenttype"></div>
				</div>
				</div>

				<div className="form-group">
					<label>Image (jpeg,jpg,png,svg)<span class="required" style={{ color: "red" }} >* </span></label>
					<div className="choose-file">
					{this.state.isHidden && <div className="image_success"></div>}
					<span className="profile_btn"><input type="file" name="file" onChange={this.onChangeHandler} /></span>
					</div>
					{preview}
					{/* {image_preview?<a href="javascript:void(0);">X</a>:''} */}
					<div className="errorfile"></div>  
				</div> 
				
			</div>
                  
			<div className="form-right">
			<div className="form-group">
					<label>Title<span class="required" style={{ color: "red" }} >* </span> : </label>
				    <input type="text" name="title" onChange={this.handleInputChange} className="form-control" value={this.state.title}/>
					<div className="errortab_title"></div>
				</div>
				<div className="form-group">					
					<label>Description<span class="required" style={{ color: "red" }} >* </span> : </label>
						<textarea type="text" name='description' onChange={this.handleInputChange} className='form-control' value={this.state.description} />
					<div className="errordescription"></div>
				</div>
				<div className="form-group">					
					<label>Status<span class="required" style={{ color: "red" }} >* </span> :</label>
						   <Select 
						   name="status"
						   value={selectedOption?selectedOption:{ value: 'active', label: 'Active'}}
                            options={lang.common.status_option} 
							isSearchable = {false}
                            onChange={this.handleChange}
                            />
	           </div> 
				{/* <div className="form-group">					
					<label>Content:</label>
						<textarea type="text" name='content' onChange={this.handleInputChange} className='form-control' value={this.state.content} />
					<div className="errorcontent"></div>
				</div> */}

				
			  
             

			</div>	
			</div>		
			<div className="btn-group export">	
				<button className="btn btn_orange btn_minwid login_submit animate-btn2" type="submit" >
									{/* <span className="load-	data"></span>  */}
									Submit
			   </button>
			 
			</div>
			</form>
			</div>
				
		</div>	
	</div>
		 	
    </div>
    );
  }
}


export default (withRouter(Add));